
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from '../CustomImage.component.vue';
  import ArticleRubricList from './ArticleRubricList.component.vue';
  import {Utils} from '@/scripts/Utils';

  const maxDisplayedChars = 200;

  /**
   * @desc carte de résumé d'un article
   */
  @Component({
    components: {
      'c-img' : CustomImage,
    },
  })
  export default class ArticleSumCard extends Vue {
    /**
     * @desc l'article à résumer
     */
    @Prop({default: () => {return new Article();}})
    article? : Article;

    /**
     * @desc transformation de la date indiquée en texte compréhensible
     */
    private getStrDate(data : Date) : string {
      let day = data.getDate().toString();
      if(day.length === 1) day = '0' + day;

      return day + '/' + (data.getMonth()+1) + "/" + data.getFullYear();
    }

    /**
     * @desc demande l'édition de l'article
     */
    private editArticle() : void {
      if(!this.article) return;
      this.$router.push('/classe/edition/' + this.article.id);
    }
  }
