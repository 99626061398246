
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import ArticleSumCard from '@/components/articles/ArticleSumCard.component.vue';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';


/**
 * @desc page d'affichage des articles non validés créés par la classe connectée
 */
  @Component({
    components: {
      'article-sum-card' : ArticleSumCard
    },
  })
  export default class MyArticles extends Vue {
    articles : Array<Article> = [];

    mounted() : void{
      if(AuthenticationService.getUserStatus() !== Consts.user_status_classroom){
        this.$router.push('/');
        return;
      }

      if(AuthenticationService.isLoggedIn() &&  AuthenticationService.getUserStatus() === Consts.user_status_classroom){
        this.loadMyArticles();
      }
    }

    /**
     * @desc charge les articles non validés créés par la classe connectée
     */
    loadMyArticles() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getMyArticles, headers)
        .then((response) => {
          console.log(response);
          this.articles = response.data.map((x: any) => new Article(x));
        })
        .catch(error => console.log(error))
    }
  }
